import React,{Component} from 'react';
import '../payment-gateway/payment-gateway.scss';
import payment_integration_img from '../../images/payment_integration_img.png';

class PaymentGateway extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="payment_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_text">
                            {/* <h1 className="main_title">Payment Gateway <br></br> Integration</h1> */}
                            <h1 className="main_title">Integration</h1>
                            <p>The focus is on Integrating Heterogenous platforms. API integrations are handled with utmost professionalism and make the things look easy. Multiple complex infrastrucrures were been merged by adhering to the latest Micro services Integration patterns.</p>
                            <a className="primary_btn" href="#">Learn More</a>
                        </div>
                        <div className="right_img">
                            <img src={payment_integration_img} alt="Payment Gateway"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default PaymentGateway;