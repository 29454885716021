import React, {Component} from 'react';
import './career.scss';
import CareerBanner from '../../component/career-banner/career-banner.js';
import CareersServices from '../../component/careers-services/careers-services.js';
import GetinTouch from '../../component/get-in-touch/get-in-touch.js';
import LookingToGrow from '../../component/looking-to-grow/looking-to-grow.js';

class Career extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="carrier">
                <CareerBanner/>
                <CareersServices/>
                <GetinTouch/>
                <LookingToGrow/>
            </div>
        )
    }
}

export default Career;