import React, {Component} from 'react';
import '../aboutus/about.scss';
import AboutBanner from '../../component/about-banner/about-banner.js';
import WhatAreWe from '../../component/what-are-we/what-are-we.js';
import FindMap from '../../component/find-map/find-map.js';

class AboutUs extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0)
    }

    render() {
        return(
            <div className="aboutus">
                <AboutBanner/>
                <WhatAreWe/>
                <FindMap/>
            </div>
        )
    }
} 
export default AboutUs;