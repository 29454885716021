import React, {Component} from 'react';
import '../looking-to-grow/looking-to-grow.scss';
import looking_to_grow from '../../images/looking_to_grow.png';

class LookingToGrow extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="looking_to_grow_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_img">
                            <img src={looking_to_grow} alt="Looking To Grow"></img>
                        </div>
                        <div className="right_text">
                            <h1 className="main_title">We do things <br></br>differently.</h1>
                            <p>Become a part of an energized, international team that changes the landscape of the digital world. We are always seeking talented web and mobile-engineers, and more.</p>
                            {/* <div className="jobs_btn">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.638 14.638"><path data-name="Icon awesome-smile" d="M7.319 0a7.319 7.319 0 107.319 7.319A7.318 7.318 0 007.319 0zM9.68 4.957a.944.944 0 11-.944.944.943.943 0 01.944-.944zm-4.722 0a.944.944 0 11-.944.944.943.943 0 01.944-.944zm5.749 5.023a4.408 4.408 0 01-6.776 0 .473.473 0 01.726-.6 3.468 3.468 0 005.324 0 .473.473 0 01.726.6z" fill="#e17743"/></svg>
                                <a href="#">See available Jobs</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default LookingToGrow;