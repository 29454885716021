import React, {Component} from 'react';
import '../what-are-we/what-are-we.scss';
import about_what_are_we from '../../images/about_what_are_we.png';

class WhatAreWe extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="what_are_we">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_img">
                            <img src={about_what_are_we} alt="Image"></img>
                        </div>
                        <div className="right_text">
                            <h1 className="main_title">What are we?</h1>
                            {/* <p>Sameer Services Solutions LLC is a tech consulting services company based out Dubai, UAE. We help raise your business to the next level with groundbreaking strategy, fresh technologies and innovation advisors that help drive business value. Technology will never stop evolving, its the nature of the beast. So having a technical innovation advisory by your side is vital: to tailor a strategy that fits your needs and to uncover the right tech for your toughest business problems - no matter the industry.
                                <br></br><br></br>
                                Let us help you steer your company with the latest technology, design thinking and modern day agility, while bringing a fresh perspective to your legacy systems - at a pace that works for you!</p> */}

                                <p>Sameer Services Solutions LLC is a tech consulting services company based out Bengaluru, India. We help raise your business to the next level with groundbreaking strategy, fresh technologies and innovation advisors that help drive business value. Technology will never stop evolving, its the nature of the beast. So having a technical innovation advisory by your side is vital: to tailor a strategy that fits your needs and to uncover the right tech for your toughest business problems - no matter the industry.
                                <br></br><br></br>
                                Let us help you steer your company with the latest technology, design thinking and modern day agility, while bringing a fresh perspective to your legacy systems - at a pace that works for you!</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default WhatAreWe;