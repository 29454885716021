import React, {Component} from 'react';
import '../artificial-intelligence/artificial-intelligence.scss';
import artificial_intelligence_img from '../../images/artificial_intelligence_img.png';

class ArtificialIntelligence extends Component {
    constructor (props) {
        super(props)
    }

    render() {
        return (
            <div className="artificial-intelligence_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_text">
                            <h1 className="main_title">Artificial Intelligence</h1>
                            <p>We are dedicated to design artificial intelligence systems that are self-sustainable and context-aware. The powerful designs and products created by our team of experts are based on high-end and the latest technological advances.</p>
                            {/* <ul>
                                <li>Machine learning</li>
                                <li>Natural language processing</li>
                                <li>Cognitive Service Management</li>
                                <li>Predictive analytics</li>
                                <li>Chatbot Development</li>
                                <li>Deep Learning</li>
                            </ul> */}
                            <a className="secondary_btn" href="#">Learn More</a>
                        </div>
                        <div className="right_img">
                            <img src={artificial_intelligence_img} alt="Artificial Intelligence"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ArtificialIntelligence ;