import React, {Component} from 'react';
import './career-banner.scss';
import careers_banner_img from '../../images/careers_banner_img.png';

class CareerBanner extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="carrier_banner">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="banner_text">
                            <h1 className="main_title">Looking to<br></br> grow </h1>
                        </div>
                        <div className="banner_img">
                            <img src={careers_banner_img} alt="Banner Image"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CareerBanner;