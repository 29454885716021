import React,{Component} from 'react';
import './header.scss';
import '../../style/variable/variable.scss';
import Logo from '../../images/logo.png';
import { Link } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="container header">
                <header>
                    <Link className="logo" to="/"><img src={Logo} alt="Logo"></img></Link>
                    <ul className="nav">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="./about">About Us</Link></li>
                        <li><Link to="./careers" className="primary_btn">Careers</Link></li>
                    </ul>
                </header>
            </div>
        )
    }
}

export default Header;