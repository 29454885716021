import React, {Component} from 'react';
import '../find-map/find-map.scss';
import map_img from '../../images/map_img.png';

class FindMap extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="find_map">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_text">
                            <h1 className="main_title">Find us at <br></br>Map</h1>
                            <p>Explore our service offerings and subsidiaries in specific geography</p>
                            <div className="map_locate">
                                <p>Indiranagar</p>
                                <p>Bengaluru - India</p>
                            </div>
                        </div>
                        <div className="right_img">
                            {/* <img src={map_img} alt="Image"></img> */}
                            {/* <div><iframe width="100%" height="413" src="https://maps.google.com/maps?width=100%&amp;height=413&amp;hl=en&amp;q=The%20Libra%20Tech%2C%20%20Aspect%20Towers%2C%204th%20floor%2C%20Business%20Bay%2C%20Dubai%20-%20United%20Arab%20Emirates.+(The%20Libra%20Tech)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.maps.ie/draw-radius-circle-map/">Create radius map</a></iframe></div> */}
                            {/* <div className="iframe_block"><iframe width="100%" height="413" src="https://www.google.co.in/maps/place//@12.9699398,77.6336592,17z/data=!4m3!1m2!2m1!1sSameer+Services+Solutions+Pvt+LTD+3rd+floor,+No.+3157+Indranagar+Double+road+Appareddypalya,+Bangalore+560008" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.maps.ie/draw-radius-circle-map/">Radius map tool</a></iframe></div> */}
                            <div className="iframe_block">
                            <div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" height="413" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=https://www.google.com/maps/@12.9782952,77.6340809,16z&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FindMap;