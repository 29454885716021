import React, {Component} from 'react';
import '../get-in-touch/get-in-touch.scss';
import form_image from '../../images/form_image.png';
import $ from 'jquery'
class GetinTouch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            cover: '',
            imageData: '',
            file_name: '',
            uploadSuccess: false,
            uploadFail:false,
            loading: false
        }
        this.sendResume = this.sendResume.bind(this)
       
    }

    validateForm() {
        if((this.state.name != "" && this.state.email != "") && (this.state.cover && this.state.file_name) ){
            return true
        }else {
            debugger
            return false
        }
    }


    sendResume() {
        this.setState({loading:true})
        if(this.validateForm()) {
            debugger
            window.Email.send({
                Host: 'smtp.elasticemail.com',
                Username : "manoj@thelibratech.com",
                Password : "A97CFCA48038CD70CFCA9C43BBE8E73845F7",
                To : ['manoj.officialmail@gmail.com','manoj@thelibratech.com'],
                From : 'manoj@thelibratech.com',
                Subject : 'Applying for libra Tech' + ' ' + `${this.state.name}`,
                Body : this.state.cover,
                Attachments : [
                    {
                        name : this.state.file_name,
                        data : this.state.imageData
                    }]
            }).then(
              (message) => {
                  this.setState({uploadSuccess:true, name:'', email:'',cover:'',file_name:''},() => {
                      setTimeout(() => {
                        this.setState({uploadSuccess:false,loading:false,})
                      },10000)
                  })
              })
        } else {
            this.setState({uploadFail: true,loading:false},() => {
                setTimeout(() => {
                    this.setState({uploadFail:false,loading: false})
                },10000)
            })
        }
    }

    setName(name) {
        this.setState({name})
    }

    setEmail(email) {
        this.setState({email})
    }

    setCover(cover) {
        this.setState({cover})
    }

     toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    setResume = async (event) => {
        let files = event.target.files
        let file = files[0];
        const value = await this.toBase64(event.target.files[0])
        let dataUri = "data:" + file.type + ";base64," + value;
        this.setState({imageData: value,file_name: file.name})
    }

    render() {
        return(
            <div className="get_in_touch_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <h1 className="main_title">Hello! <br></br> Join with Us. </h1>
                        <p>We love email. Send us a friendly one with a little about what you’re working on, when you want what, for how much, and we’ll be happy to answer questions and help you figure out whether it’s a good fit.</p>
                        <div className="questions">
                            <p>If you need to join  with us, just fill in the contact form, and we will answer you shortly.</p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 113"><defs><filter id="a" x="0" y="0" width="113" height="113" filterUnits="userSpaceOnUse"><feOffset dx="-7" dy="7"/><feGaussianBlur stdDeviation="5" result="blur"/><feFlood flood-opacity=".051"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g data-name="Group 42"><g filter="url(#a)"><circle data-name="Ellipse 19" cx="41.5" cy="41.5" r="41.5" transform="translate(22 8)" fill="#fff"/></g><path data-name="Path 143" d="M52.025 38.778h-3.544A2.484 2.484 0 0046 41.259v16.3a2.484 2.484 0 002.481 2.481h3.544a2.484 2.484 0 002.481-2.481v-16.3a2.484 2.484 0 00-2.481-2.481z" fill="#e17743"/><path data-name="Path 144" d="M74.354 39.841h-6.691a13.689 13.689 0 001.063-5.671c0-4.252-3.194-5.67-4.607-5.67s-2.127.709-2.127 4.25c0 3.368-3.262 6.079-5.36 7.475V57.79c2.27 1.05 6.813 2.607 13.866 2.607h2.268a5.661 5.661 0 005.586-4.707l1.588-9.215a5.668 5.668 0 00-5.585-6.635z" fill="#816dd6"/></g></svg>
                        </div>
                        <div className="career_form">
                            <form className="form">
                                <p className="error_msg">Please fill your Name</p>
                                <input value={this.state.name} type="text" name="name" onChange={(event) => this.setName(event.target.value)} required placeholder="Name"></input>
                                <p className="error_msg">Please fill your Email</p>
                                <input value={this.state.email} type="text" name="name" onChange={(event) => this.setEmail(event.target.value)} required placeholder="Email"></input>
                                <textarea value={this.state.cover} onChange={(event) => this.setCover(event.target.value)}  required placeholder="Cover Letter" name="message" rows="10" cols="30"></textarea>
                                <div className="form_btn_wrapper">
                                    <div className="file_div">
                                        <input onChange={(event) => this.setResume(event)} type="file"/>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.978 14.684"><path data-name="Icon awesome-cloud-upload-alt" d="M17.621 6.379a3.15 3.15 0 00-2.937-4.281 3.131 3.131 0 00-1.747.531 5.245 5.245 0 00-9.79 2.615c0 .089 0 .177.007.266a4.721 4.721 0 001.566 9.174h12.062a4.2 4.2 0 00.839-8.306zm-4.727 2.013h-2.143v3.671a.526.526 0 01-.524.524H8.653a.526.526 0 01-.524-.524V8.391H5.985a.523.523 0 01-.37-.895L9.07 4.042a.526.526 0 01.741 0l3.455 3.455a.524.524 0 01-.371.894z"/></svg>
                                        <label>Upload File</label>
                                        {/* <button className="btn" type="file">
                                            Upload</button>
                                        <p>No files chosen</p> */}
                                    </div>
                                <a className="secondary_btn" href='javascript:void();' onClick={this.sendResume}>{this.state.loading ? 'Sending...': 'Submit Now'}</a>
                                </div>
                                {!this.state.file_name ? '' : this.state.file_name}
                                {this.state.uploadSuccess && <p className="success_msg">Thanks ,We have got your profile and we will get back to you soon!</p>}
                                {this.state.uploadFail && <p className="error_msg_upload">Please fill all details before upload | drop mail to manoj@thelibratech.com</p>}
                            </form>
                            <div className="right_img">
                                <img src={form_image} alt="Banner Image"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GetinTouch;