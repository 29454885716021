import React, {Component} from 'react';
import '../mobile-application/mobile-application.scss';
import mobile_application_img from '../../images/mobile_application_img.png';
import mobile1 from '../../images/mobile1.png';
import mobile2 from '../../images/mobile2.png';
import mobile3 from '../../images/mobile3.png';

class MobileApplication extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="mobile_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_img">
                            <img src={mobile_application_img} alt="Mobile Application"></img>
                        </div>
                        <div className="right_text">
                            <h1 className="main_title">Mobile Application</h1>
                            <p>Sameer Services Solutions, we are good in Mobile and Web App Development Company, possesses extensive experience in mobile app development in IOS, Android, native and html5 cross-platform apps. We can help you to select the right technology for your mobile app projects.</p>
                            <a className="primary_btn" href="#">Learn More</a>
                            <ul className="specs">
                                <li className="circle_one">
                                    <img src={mobile1} alt="Completely Mobile Ready"></img>
                                    <span>Completely <br></br> Mobile Ready</span>
                                </li>
                                <li className="circle_two">
                                    <img src={mobile2} alt="Completely Mobile Ready"></img>
                                    <span>Highly <br></br> Responsive <br></br> Tools</span>
                                </li>
                                <li className="circle_three">
                                    <img src={mobile3} alt="Completely Mobile Ready"></img>
                                    <span>Cross-browser <br></br> Compatible <br></br> Design</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileApplication;