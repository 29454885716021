import React,{Component} from 'react';
import '../banner/banner.scss';
import banner_img1 from '../../images/banner_img1.png';

class BannerSection extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="banner_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="banner_text">
                            <h1 className="main_title">Your Business Deserves <br></br> Better Software</h1>
                            <p>A company that embraces challenges!</p>
                            <p className="banner_info"> Let us help you steer your company with the latest technology, design thinking and modern day agility, while bringing a fresh perspective to your legacy systems - at a pace that works for you!</p>
                            <a className="secondary_btn" href="#">Learn More</a>
                            {/* <div className="promotes">
                                <h4>Better software <br></br> promotes <br></br> sales & profits</h4>
                            </div> */}
                        </div>
                        <div className="banner_img">
                            <img src={banner_img1} alt="Banner Image"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BannerSection;