import React,{Component} from 'react';
import './home.scss';
import BannerSection from '../../component/banner/banner.js';
import HigherBusiness from '../../component/higher-business/higher-business.js';
import PaymentGateway from '../../component/payment-gateway/payment-gateway.js';
// import BankingProcess from '../../component/banking-process/banking-process.js';
import ArtificialIntelligence from '../../component/artificial-intelligence/artificial-intelligence.js';
import MobileApplication from '../../component/mobile-application/mobile-application.js';
import '../../fonts/fonts.scss';

class Home extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0)
    }

    render() {
        return(
            <div>
                <BannerSection/>
                <HigherBusiness/>
                <PaymentGateway/>
                {/* <BankingProcess/> */}
                <ArtificialIntelligence/>
                <MobileApplication/>
            </div>
        )
    }
}

export default Home;
