import React,{Component} from 'react';
import '../footer/footer.scss';
import footer_img1 from '../../images/footer_img1.png';
import Logo from '../../images/logo.png';
import { Link } from "react-router-dom";

class Footer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="flex_wrap">
                        <div className="footer_top">
                            <div className="left_text">
                                <h1 className="main_title">Let’s talk &<br></br> start working</h1>
                                <a>Contact us</a>
                            </div>
                            <div className="right_img">
                            <img src={footer_img1} alt="Image"></img>
                            </div>
                        </div>
                        <div className="footer_bottom">
                            <div className="footer_logo">
                            <Link to="/"><img src={Logo} alt="Logo"></img></Link>
                            </div>
                            <div className="contact_details">
                                <ul>
                                    <li><strong>Office</strong></li>
                                    {/* <li>Sameer Services Solutions Pvt LTD<br></br>
                                        3rd floor, No. 3157 <br></br>
                                        Indranagar Double road <br></br>
                                        Appareddypalya, Bangalore 560008
                                    </li> */}
                                    <li>
                                        SAMEER SERVICES SOLUTIONS Pvt LTD <br/>
                                        #32, second floor, NoMads 86 <br/>
                                        CMH Road, next to union bank, <br/>
                                        Bangalore 38
                                    </li>
                                </ul>
                                <ul>
                                    <li><strong>Start a conversation</strong></li>
                                    <li>nik@sameersolutions.com</li>
                                    <li>+91 9538907272</li>
                                </ul>
                                {/* <ul>
                                    <li><strong>Socials</strong></li>
                                    <li><a href="#">Facebook</a></li>
                                    <li><a href="#">Instagram</a></li>
                                    <li><a href="#">Twitter</a></li>
                                    <li><a href="#">Linkedin</a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_last">
                    <div className="container">
                        <div className="last_content">
                            <p>©2023 by Sameer Services Solutions Pvt LTD. Proudly created with sameersolutions.com</p>
                            <ul class="nav">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/careers">Careers</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;