import React, {Component} from 'react';
import '../about-banner/about-banner.scss';
import about_banner_img from '../../images/about_banner_img.png';

class AboutBanner extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="about_banner">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="banner_text">
                            <h1 className="main_title">About the  <br></br> Sameer Services Solutions </h1>
                        </div>
                        <div className="banner_img">
                            <img src={about_banner_img} alt="Banner Image"></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutBanner;