import React from 'react';
import './App.scss';
import Home from './container/home/home.js';
import Header from './component/header/header.js';
import Footer from './component/footer/footer.js';
import Career from './container/career/careers.js';
import AboutUs from './container/aboutus/about.js';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="main_wrapper">
      <Header/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/about" component={AboutUs}/>
        <Route path="/careers" component={Career}/>
        <Route path="*" component={Home} />
      </Switch>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
