import React, {Component} from 'react';
import '../higher-business/higher-business.scss';
import developed_image from '../../images/developed_image.png';

class HigherBusiness extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className="heigher_wrapper">
                <div className="container">
                    <div className="flex_wrap">
                        <div className="left_img">
                            <img src={developed_image} alt="Heigher Business"></img>
                        </div>
                        <div className="right_text">
                            <h1 className="main_title">Developed for Higher <br></br> Business Growth</h1>
                            <p>Offer your business with the best assistance for growth</p>
                            <p className="rgt_algn"> We bring on like-minded and talented members to <br></br>
                                help you grow a stronger business for <br></br>
                                fiercely reaching towards higher goals every day. <br></br>
                                We try to form an all-star team that deeply <br></br>
                                investigates your company culture to advertise about it.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default HigherBusiness;